<template>
  <section id="crear-campanya">
    <div>
      <b-row class="mb-5">
        <b-col md="10">
          <h1>{{ $t('Campañas') }}</h1>
          <h5 class="text-primary">
            {{ $t('confCampAd') }}
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end mr-1">
          <router-link to="/advertising/campaigns">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-overlay
        variant="transparent"
        :show="show"
      >
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          :finish-button-text="$t('dataGeneric.create')"
          :back-button-text="$t('dataGeneric.goBack')"
          :next-button-text="$t('next')"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- Información del contenido tab -->
          <tab-content
            :title="$t('advertising.CampaignInfo')"
            :before-change="validationForm"
          >
            <validation-observer
              ref="accountRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("advertising.CampaignInfo") }}
                  </h5>
                  <small class="text-muted">{{
                    $t("advertising.CampaignInfoSub")
                  }}</small>
                </b-col>

                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group :label="$t('message.tableHeader.name') + ' *'">
                    <validation-provider
                      v-slot="validationContext"
                      name="name"
                      rules="required|min:3"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('firstName')"
                        maxlength="150"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group :label="$t('dataGeneric.description')">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                    >
                      <b-form-textarea
                        v-model="comment"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('advertising.CampaignDescription')"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="{ }"
                    name="isPriority"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('Yprioridad')"
                      label-for="isPriority"
                    >
                      <b-form-checkbox
                        v-model="isPriority"
                        class="mt-2"
                        switch
                      >
                        {{ $t('prio') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <b-tabs
                    v-model="tabIndex"
                    justified
                    pills
                    class="mt-2"
                  >
                    <b-tab active>
                      <template #title>
                        <div class="align-items-center">
                          <feather-icon icon="HomeIcon" />
                          <span>{{ $t("dataGeneric.internal") }} (SSAI)</span>
                        </div>
                      </template>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="align-items-center">
                          <feather-icon icon="ToolIcon" />
                          <span>{{ $t("dataGeneric.external") }} (CSAI)</span>
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <tab-content
            v-if="tabIndex === 1"
            :title="$t('advertising.ExternalServerTitle')"
            :before-change="validationFormInfo"
          >
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("advertising.ExternalServerTitle") }}
                  </h5>
                  <small class="text-muted">{{
                    $t("advertising.ExternalServerConfig")
                  }}</small>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="externalUrl"
                    rules="url"
                  >
                    <b-form-group
                      :label="$t('advertising.ExternalServerUrl')"
                      label-for="externalUrl"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="externalUrl"
                        v-model="externalUrl"
                        autocomplete="new-password"
                        :placeholder="$t('advertising.ExternalServerUrl')"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="externalAdsUrlMobile"
                    rules="url"
                  >
                    <b-form-group
                      :label="$t('urlServExt')"
                      label-for="externalAdsUrlMobile"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="externalAdsUrlMobile"
                        v-model="externalAdsUrlMobile"
                        autocomplete="new-password"
                        :placeholder="$t('urlServExt')"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="externalAdsUrlTv"
                    rules="url"
                  >
                    <b-form-group
                      :label="$t('urlServExtTv')"
                      label-for="externalAdsUrlTv"
                      :invalid-feedback="$t('url inválida')"
                    >
                      <b-form-input
                        id="externalAdsUrlTv"
                        v-model="externalAdsUrlTv"
                        autocomplete="new-password"
                        :placeholder="$t('urlServExtTv')"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group
                    :label="$t('advertising.ExternalServerTag')"
                    label-for="externalTag"
                  >
                    <b-form-input
                      id="externalTag"
                      v-model="externalTag"
                      autocomplete="new-password"
                      :placeholder="$t('advertising.ExternalServerTag')"
                    />
                  </b-form-group>
                  <!-- </validation-provider> -->
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
            :title="$t('code.C&C')"
            :before-change="validationFormCat"
          >
            <validation-observer
              ref="catRules"
              tag="form"
            >
              <b-overlay
                no-center
                :show="contentSelected.length > 0"
                variant="transparent"
                rounded="sm"
              >
                <template #overlay>
                  <b-icon
                    icon="stopwatch"
                    variant="info"
                    scale="2"
                    shift-v="8"
                    shift-h="8"
                    class="position-absolute"
                    style="top: 0; right: 0"
                  />
                </template>
                <b-card>
                  <b-card-header class="head d-flex flex-row">
                    <div>
                      <b-card-title>{{ $t('Category') }}</b-card-title>
                      <h6 class="text-primary ">
                        {{ $t('campCat') }}
                      </h6>
                    </div>
                    <h3>
                      <b-badge
                        v-if="requiredField"
                        variant="danger"
                      >
                        <feather-icon
                          icon="AlertOctagonIcon"
                          size="50"
                          style="vertical-align: baseline;"
                        />
                        {{ $t('needAdd') }}
                      </b-badge>
                    </h3>
                  </b-card-header>
                  <b-card-body>
                    <b-overlay
                      variant="transparent"
                      :show="showCategories"
                    >
                      <b-row class="m-2">
                        <!-- people group 1 -->
                        <b-col md="6">
                          <b-row>
                            <b-col md="5">
                              <h6 class="text-primary font-weight-bold mb-2">
                                {{ $t("code.allC") }}
                                <feather-icon
                                  v-show="resCategories.length > 0"
                                  icon="PlusCircleIcon"
                                  size="20"
                                  class="text-success cursor-pointer ml-1"
                                  @click="AddCat()"
                                />
                              </h6>
                            </b-col>
                            <b-col md="5">
                              <b-form-input
                                v-model="searchCategories"
                                minlength="3"
                                :placeholder="$t('slider.searchThreeCaracCat')"
                                @input="getCategoriesThreeCharact"
                              />
                            </b-col>
                          </b-row>

                          <b-form-select
                            v-model="checkResults"
                            tag="ul"
                            multiple
                            :select-size="5"
                            class="scroll-list list-group list-group-flush  mt-2 border-0"
                          >
                            <b-form-select-option
                              v-for="c in resCategories"
                              :key="c.node.id"
                              tag="li"
                              :value="c"
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div class="ml-25 ">
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ c.node.name }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                            <b-form-select-option
                              v-if="resCategories.length == 0"
                              :value="null"
                              disabled
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div
                                  v-if="searchCategories.length <= 2"
                                  class="ml-25 "
                                >
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ threeCharSearch }}
                                  </b-card-text>
                                </div>
                                <div
                                  v-if="searchCategories.length >= 3"
                                  class="text-left mt-2 ml-2"
                                >
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ $t('subscriptions.notFound') }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <!-- people group 2 -->
                        <b-col md="6">
                          <b-row>
                            <b-col
                              md="5"
                              class="d-flex align-items-center"
                            >
                              <h6 class="text-primary font-weight-bold mb-0 mt-0">
                                {{ $t('subscriptions.selectedCategory') }}
                              </h6>
                              <feather-icon
                                v-show="resultCategorySelected.length > 0"
                                icon="XCircleIcon"
                                size="20"
                                class="text-danger cursor-pointer ml-1"
                                @click="QuitarCat()"
                              />
                            </b-col>
                            <b-col md="5">
                              <b-form-input
                                v-model="searchCatSelected"
                                :placeholder="$t('slider.searchThreeCaracCat')"
                                @input="getCategoriesSelected"
                              />
                            </b-col>
                          </b-row>
                          <b-form-select
                            v-model="checkRemove"
                            tag="ul"
                            multiple
                            :select-size="5"
                            class="scroll-list list-group list-group-flush mt-2 border-0"
                          >
                            <b-form-select-option
                              v-for="catSus in resultCategorySelected"
                              :key="catSus.node.id"
                              tag="li"
                              :value="catSus"
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div class="ml-25 ">
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ catSus.node.name }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </b-card-body>
                </b-card>
              </b-overlay>
              <b-overlay
                no-center
                :show="categorySelected.length > 0"
                variant="transparent"
                rounded="sm"
              >
                <template #overlay>
                  <b-icon
                    icon="stopwatch"
                    variant="info"
                    scale="2"
                    shift-v="8"
                    shift-h="8"
                    class="position-absolute"
                    style="top: 0; right: 0"
                  />
                </template>
                <b-card>
                  <b-card-header class="head">
                    <b-card-title>{{ $t('contents.contents') }}</b-card-title>
                    <h6 class="text-primary ">
                      {{ $t('contents.contCampg') }}
                    </h6>
                  </b-card-header>
                  <b-card-body>
                    <b-overlay
                      variant="transparent"
                      :show="showContents"
                    >
                      <b-row class="m-2">
                        <!-- people group 1 -->
                        <b-col md="6">
                          <b-row>
                            <b-col md="5">
                              <h6 class="text-primary font-weight-bold mb-2">
                                {{ $t("code.allCon") }}
                                <feather-icon
                                  v-show="resContents.length > 0"
                                  icon="PlusCircleIcon"
                                  size="20"
                                  class="text-success cursor-pointer ml-1"
                                  @click="AddCont()"
                                />
                              </h6>
                            </b-col>
                            <b-col md="5">
                              <b-form-input
                                v-model="searchContents"
                                minlength="3"
                                :placeholder="$t('slider.searchThreeCaracContent')"
                                @input="getContentsThreeCharact"
                              />
                            </b-col>
                          </b-row>

                          <b-form-select
                            v-model="checkContResults"
                            tag="ul"
                            multiple
                            :select-size="5"
                            class="scroll-list list-group list-group-flush  mt-2 border-0"
                          >
                            <b-form-select-option
                              v-for="c in resContents"
                              :key="c.node.id"
                              tag="li"
                              :value="c"
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div class="ml-25 ">
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ c.node.name }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                            <b-form-select-option
                              v-if="resContents.length == 0"
                              :value="null"
                              disabled
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div
                                  v-if="searchContents.length <= 2"
                                  class="ml-25 "
                                >
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ threeCharSearch }}
                                  </b-card-text>
                                </div>
                                <div
                                  v-if="searchContents.length >= 3"
                                  class="text-left mt-2 ml-2"
                                >
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ $t('subscriptions.notFound') }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <!-- people group 2 -->
                        <b-col md="6">
                          <b-row>
                            <b-col
                              md="5"
                              class="d-flex align-items-center"
                            >
                              <h6 class="text-primary font-weight-bold mb-0 mt-0">
                                {{ $t('selectCont') }}
                              </h6>
                              <feather-icon
                                v-show="resultContentsSelected.length > 0"
                                icon="XCircleIcon"
                                size="20"
                                class="text-danger cursor-pointer ml-1"
                                @click="QuitarCont()"
                              />
                            </b-col>
                            <b-col md="5">
                              <b-form-input
                                v-model="searchContSelected"
                                :placeholder="$t('slider.searchThreeCaracContent')"
                                @input="getContentsSelected"
                              />
                            </b-col>
                          </b-row>
                          <b-form-select
                            v-model="checkContRemove"
                            tag="ul"
                            multiple
                            :select-size="5"
                            class="scroll-list list-group list-group-flush mt-2 border-0"
                          >
                            <b-form-select-option
                              v-for="cont in resultContentsSelected"
                              :key="cont.node.id"
                              tag="li"
                              :value="cont"
                              class="list-group-item"
                            >
                              <div class="d-flex ">
                                <div class="ml-25 ">
                                  <b-card-text class="mb-0 font-weight-bold">
                                    {{ cont.node.name }}
                                  </b-card-text>
                                </div>
                              </div>
                            </b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </b-card-body>
                </b-card>
              </b-overlay>
            </validation-observer>
          </tab-content>
          <tab-content
            :title="$t('message.tableHeader.date')"
            :before-change="validationFormSocial"
          >
            <validation-observer
              ref="socialRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    {{ $t("message.tableHeader.date") }}
                  </h5>
                  <small class="text-muted">
                    {{ $t("advertising.CampaignDateRange") }}</small>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    v-slot="validationContext"
                    name="inicioDateTime"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :label="$t('advertising.CampaignDateStart')+' *'"
                    >
                      <flat-pickr
                        id="inicioDateTime"
                        v-model="inicioDateTime"
                        :state="validationContext.errors.length > 0 ? false : null"
                        class="form-control"
                        :config="{
                          enableTime: true,
                          enableSeconds: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <validation-provider
                    v-slot="validationContext"
                    name="finalDateTime"
                    rules="required"
                  >
                    <b-form-group
                      class="mb-2 mr-1"
                      :placeholder="$t('contents.datePublishing')"
                      :label="$t('advertising.CampaignDateEnd')+' *'"
                    >
                      <flat-pickr
                        id="finalDateTime"
                        v-model="finalDateTime"
                        :state="validationContext.errors.length > 0 ? false : null"
                        class="form-control "
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:S'
                        }"
                      />
                      <small class="text-danger">{{ messageValidation(validationContext) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-overlay>
    </div>
  </section>
</template>

<script>
import {
  isEmpty, isUserInGroup, messageError, showToast, utf8ToB64,
} from '@/store/functions'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BCardText,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormTextarea,
  BCard,
  BBadge,
  BRow,
  BCardHeader, BCardTitle, BCardBody,
  BCol,
  BOverlay,
  BIcon,
  BFormGroup,
  BFormInput,
  BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { getUserData } from '@/auth/utils'

import * as constants from '@core/utils/constants'

export default {
  components: {
    BCard,
    BOverlay,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BFormSelectOption,
    ValidationProvider,
    BIcon,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      userData: getUserData(),
      name: '',
      externalFillRate: null,
      comment: '',
      externalUrl: null,
      externalTag: null,
      inicioDateTime: null,
      externalAdsUrlMobile: null,
      externalAdsUrlTv: null,
      isPriority: false,
      isSponsor: false,
      finalDateTime: null,
      show: false,
      required,
      tabIndex: 0,
      categorySelected: [],
      contentSelected: [],
      allCats: [],
      allConts: [],
      checkResults: [],
      checkRemove: [],
      checkContResults: [],
      checkContRemove: [],
      resultContentsSelected: [],
      resultCategorySelected: [],
      resCategories: [],
      resContents: [],
      threeCharSearch: this.$t('slider.searchThreeCaracDefault'),
      showContents: false,
      showCategories: false,
      searchCategories: '',
      searchContents: '',
      searchContSelected: '',
      searchCatSelected: '',
      requiredField: false,
    }
  },
  watch: {
    resCategories() {
      if (this.resCategories.length !== 0) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    resContents() {
      if (this.resContents.length !== 0) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchContents(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchContents.length <= 2) {
          this.resContents = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracContent')
        }
      }
    },
    searchCategories(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.searchCategories.length <= 2) {
          this.resCategories = []
          this.threeCharSearch = this.$t('slider.searchThreeCaracCat')
        }
      }
    },
    categorySelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
    contentSelected() {
      if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
        this.requiredField = true
      } else {
        this.requiredField = false
      }
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.getCheckClient()
    this.inicioDateTime = new Date()
    const fechafinal = new Date()
    this.finalDateTime = fechafinal.setDate(fechafinal.getDate() + 1)
  },

  methods: {
    messageValidation(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    getCheckClient() {
      axios
        .post('', {
          query: `
        {
          allClients(clientId:"${this.userData.profile.client.clientId}") {
            edges {
              node {
               id
                externalFillRate
               name
              }
            }
          }
        }
        `,
        })
        .then(result => {
          messageError(result, this)

          this.externalFillRate = result.data.data.allClients.edges[0].node.externalFillRate
        })
        .catch(() => { })
    },
    formSubmitted() {
      this.show = true
      if (isEmpty(this.inicioDateTime)) {
        this.inicioDateTime = new Date()
      }
      if (isEmpty(this.finalDateTime)) {
        const fechafinal = new Date()
        this.finalDateTime = fechafinal.setDate(fechafinal.getDate() + 1)
      }

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
        mutation($name: String!, $description: String,$isPriority: Boolean, $client: ID!, $start: DateTime!, $end: DateTime!, $externalAdsUrlMobile: String, $externalAdsUrlTv: String, $externalUrl: String, $externalTag: String, $externalPenetration: Float, $internalPenetration: Float){
          createCampaign(input:{
            name: $name,
            description: $description,
            isPriority: $isPriority,
            client: $client,
            startDate: $start,
            finalDate: $end,
            externalAdsTag: $externalTag,
            externalAdsUrl: $externalUrl,
            externalAdsUrlMobile: $externalAdsUrlMobile,
            externalAdsUrlTv: $externalAdsUrlTv,
            externalsAdsPenetration: $externalPenetration,
            contentsFillRate: $internalPenetration,
            ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}",`}            
             ${this.categorySelected.length !== 0
    ? `categories: [${this.getIdArray(this.categorySelected)}],`
    : ' '
}
              ${this.contentSelected.length !== 0
    ? `contents: [${this.getIdArray(this.contentSelected)}],`
    : ' '
}
          }){
          campaing {
            id
            name
            description
            startDate
            finalDate
            externalAdsTag
            isPriority
            externalAdsUrl
            externalAdsUrlMobile
            externalAdsUrlTv
            externalsAdsPenetration
            contentsFillRate
          }
        }
        }
      `
      const variables = {
        name: this.name,
        description: this.comment,
        isPriority: this.isPriority,
        client: this.userData.profile.client.id,
        start: new Date(this.inicioDateTime),
        end: new Date(this.finalDateTime),
        externalTag:
          (this.tabIndex === 1 && this.externalTag != null) ? this.externalTag : '',
        externalUrl:
          (this.tabIndex === 1 && this.externalUrl != null) ? this.externalUrl : '',
        externalAdsUrlMobile:
          (this.tabIndex === 1 && this.externalAdsUrlMobile != null) ? this.externalAdsUrlMobile : '',
        externalAdsUrlTv:
          (this.tabIndex === 1 && this.externalAdsUrlTv != null) ? this.externalAdsUrlTv : '',
        externalPenetration: this.tabIndex === 0 ? 0.0 : this.externalFillRate,
        internalPenetration: this.tabIndex === 0 ? (1 - this.externalFillRate) : 0.0,
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)

      axios.post('', data, config).then(res => {
        messageError(res, this)

        this.show = false
        showToast(this.$t('success'), 1, this)

        if (res.status === 200) {
          this.$router.push('/advertising/campaigns')
        }
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormCat() {
      return new Promise((resolve, reject) => {
        this.$refs.catRules.validate().then(() => {
          if (!this.isSponsor) {
            resolve(true)
          } else if (this.categorySelected.length === 0 && this.contentSelected.length === 0) {
            this.requiredField = true
            document.scrollingElement.scrollTop = 0
            reject()
          } else {
            this.requiredField = false
            resolve(true)
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getContents() {
      this.showContents = true
      axios
        .post('', {
          query: `
           query{
            contentsUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchContents}"
            ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}         
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arrayTotal = response.data.data.contentsUnlimited.edges
          const arrayselec = []

          for (let i = 0; i < arrayTotal.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.contentSelected.length; j += 1) {
              if (this.contentSelected[j].node.id === arrayTotal[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              arrayselec.push(arrayTotal[i])
            }
          }
          this.allConts = arrayselec.slice()
          this.resContents = arrayselec.slice()
          this.showContents = false
        })
    },
    getCategories() {
      this.showCategories = true
      axios
        .post('', {
          query: `
           query{
            categoriesUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"
            ${this.isSponsor ? `,filter_Sponsor:"${this.userData.id}"` : ''}            
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.categoriesUnlimited.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }
            if (!bool) {
              array.push(arr2[i])
            }
          }
          this.resCategories = array.slice()
          this.allCats = array.slice()
          this.showCategories = false
        })
    },
    searchByName() {
      this.resCategories = this.allCats.filter(item => item.node.name.toLowerCase().includes(this.searchCategories.toLowerCase()))
      this.resCategories.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
    QuitarCont() {
      if (this.checkContRemove.length !== 0) {
        this.allConts = [...this.checkContRemove, ...this.allConts]
        this.checkContRemove.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.checkContRemove = []
        this.searchContentsByName()
        this.getContentsSelected()
      } else {
        this.allConts = [...this.resultContentsSelected, ...this.allConts]

        this.resultContentsSelected.forEach(element => {
          const idx = this.contentSelected.indexOf(element)
          if (idx !== -1) {
            this.contentSelected.splice(idx, 1)
          }
        })
        this.resultContentsSelected = []
        this.searchContentsByName()
      }
    },
    QuitarCat() {
      if (this.checkRemove.length !== 0) {
        this.allCats = [...this.checkRemove, ...this.allCats]
        this.checkRemove.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.checkRemove = []
        this.searchByName()
        this.getCategoriesSelected()
      } else {
        this.allCats = [...this.resultCategorySelected, ...this.allCats]

        this.resultCategorySelected.forEach(element => {
          const idx = this.categorySelected.indexOf(element)
          if (idx !== -1) {
            this.categorySelected.splice(idx, 1)
          }
        })
        this.resultCategorySelected = []
        this.searchByName()
      }
    },
    AddCat() {
      if (this.checkResults.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.checkResults]
        this.getCategoriesSelected()
        this.checkResults.forEach(element => {
          const idx = this.resCategories.indexOf(element)
          if (idx !== -1) {
            this.resCategories.splice(idx, 1)
          }
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.checkResults = []
        this.searchByName()
      } else {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]
        this.getCategoriesSelected()
        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })
        this.resCategories = []
        this.searchByName()
      }
    },
    AddCont() {
      if (this.checkContResults.length !== 0) {
        this.contentSelected = [...this.contentSelected, ...this.checkContResults]
        this.getContentsSelected()
        this.checkContResults.forEach(element => {
          const idx = this.resContents.indexOf(element)
          if (idx !== -1) {
            this.resContents.splice(idx, 1)
          }
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.checkContResults = []
        this.searchContentsByName()
      } else {
        this.contentSelected = [...this.contentSelected, ...this.resContents]
        this.getContentsSelected()
        this.resContents.forEach(element => {
          this.allConts = this.allConts.filter(e => element.node.id !== e.node.id)
        })
        this.resContents = []
        this.searchContentsByName()
      }
    },
    getCategoriesSelected() {
      this.resultCategorySelected = this.categorySelected.filter(item => item.node.name.toLowerCase().includes(this.searchCatSelected.toLowerCase()))
    },
    getContentsSelected() {
      this.resultContentsSelected = this.contentSelected.filter(item => item.node.name.toLowerCase().includes(this.searchContSelected.toLowerCase()))
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    getCategoriesThreeCharact() {
      if (this.searchCategories.trim().length >= 3) {
        this.getCategories()
      }
    },
    getContentsThreeCharact() {
      if (this.searchContents.trim().length >= 3) {
        this.getContents()
      }
    },
    searchContentsByName() {
      this.resContents = this.allConts.filter(item => item.node.name.toLowerCase().includes(this.searchContents.toLowerCase()))
      this.resContents.sort((firstParams, secondParams) => firstParams.node.name.localeCompare(secondParams.node.name))
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#crear-campanya .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#crear-campanya .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#crear-campanya .wizard-card-footer {
  padding: 20px;
}

#crear-campanya .required {
  border: 1px solid;
  border-color: #ea5455 !important;
}
</style>
